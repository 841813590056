<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04">
          <img
            src="@/assets/front_component/images/assets/back02.png"
            alt="雲"
          />
        </div>
      </div>
      <div class="left_area">
        <div class="img01">
          <img
            src="@/assets/front_component/images/assets/back01.png"
            alt="雲"
          />
        </div>
      </div>
    </div>
    <div class="en_ttl en"><span>PHOTOLOG</span></div>
    <div class="content_wrap">
      <h2>フォトログ</h2>
      <div class="mascot_wrap">
        <img
          src="@/assets/front_component/images/assets/meguru05.png"
          alt="めぐる君"
        />
      </div>
    </div>
  </div>
  <main>
    <div class="section_all_wrap">
      <div class="content_in_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li>
              <router-link :to="{ name: 'Front top' }">ホーム</router-link>
            </li>
            <li><span>フォトログ一覧</span></li>
          </ul>
        </div>
        <div class="content">
          <div class="innar">
            <div class="board_wrap">
              <div class="photo_wrap">
                <ul id="gallery_list" v-if="messages.length != 0">
                  <li
                    class="gallery_wrap"
                    v-for="(message, index) in messages"
                    :key="message.id"
                  >
                    <a
                      class="pop-modal gallery_show"
                      :href="'#inline-wrap0' + index"
                    >
                      <div
                        class="img_wrap"
                        v-bind:style="{
                          backgroundImage: 'url(' + message.imgpath + ')',
                        }"
                      >
                        <img
                          v-if="!message.imgpath"
                          src="https://www.kdfc.com/wp-content/themes/uscradio/dist/images/spinner-1.gif"
                          width="25"
                          height="25"
                          style="margin-right: 10px;position: relative;top: -76px;left: 53px;"
                        />
                        <div class="hover_text">
                          <div class="txt_wrap">
                            <h3 class="gallery_ttl">{{ message.content }}</h3>
                          </div>
                        </div>
                      </div>
                    </a>
                    <div
                      :id="'inline-wrap0' + index"
                      class="mfp-hide inline-wrap"
                      data-group="1"
                    >
                      <div class="img_list">
                        <div class="img_wrap">
                          <img :src="message.imgpath" />
                        </div>
                      </div>
                      <div class="txt_wrap photo">
                        <p v-if="message.from_user" class="name">
                          <span v-html="message.from_user.group.name"></span>
                          <span v-html="message.from_user.last_name"></span
                          ><span v-html="message.from_user.first_name"></span>
                        </p>
                        <h3>{{ message.content }}</h3>
                      </div>
                      <button
                        title="Close (Esc)"
                        type="button"
                        class="mfp-close"
                      >
                        ×
                      </button>
                    </div>
                  </li>
                </ul>

                <div v-else class="p_t50">
                  <div class="half-circle-spinner">
                    <div class="circle circle-1"></div>
                    <div class="circle circle-2"></div>
                  </div>
                </div>
              </div>
              <div id="load" style="display: none">
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>
              <div
                id="more_btn"
                class="t_c m_t20"
                v-if="6 < total && now_post_num < total"
              >
                <div class="btn_wide">
                  <button v-on:click="messageListmore">さらに読み込む</button>
                </div>
              </div>
            </div>
          </div>
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/bard01.png"
              alt="青鳥"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/bard02.png"
              alt="緑鳥"
            />
          </div>
        </div>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/kusa02.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/kusa05.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap09">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap10">
            <img
              src="@/assets/front_component/images/assets/kusa07.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap11">
            <img
              src="@/assets/front_component/images/assets/kusa06.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap12">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap13">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap14">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap15">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap16">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap17">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kikyu01.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/heri01.png"
              alt="ヘリコプター"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kikyu02.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kikyu03.png"
              alt="気球"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img
        src="@/assets/front_component/images/assets/menu01.png"
        alt="メニュー"
      />
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
      <ul class="menu_list">
        <!-- <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'news' }}">お知らせ</router-link></li> -->
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'thanks' } }"
            >サンクス&ホメロンカード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'idea' } }"
            >アイディアカード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'daily' } }"
            >気づき日報</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'photolog' },
            }"
            >フォトログ</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'column' } }"
            >コラム</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import User from "@/apis/User";
import Groups from "@/apis/Groups";
import message from "@/apis/Message";
import dayjs from "dayjs";
import MessageColumn from "@/const/MessageColumn.json";
import MessageType from "@/const/MessageType.json";
import $ from "jquery";

import "@/assets/front_component/js/functions.js";
import "@/assets/front_component/js/jquery.magnific-popup.min.js";
import striptags from "striptags";

export default {
  name: "message",
  data() {
    return {
      messages: [],
      tempMessages: [],
      images: [],
      now_post_num: 18, // 表示されている数
      get_post_num: 18, // 取得する数
      loader: false,
      more_load: false,
      autoIncrease: 1,
    };
  },
  watch: {
    messages(val) {
      // this.tempMessages = val;
    },
  },
  components: {},
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
  },
  created() {
    this.message_type = "photolog";
    this.messageList(this.message_type, this.now_post_num);
    this.messagetype = MessageType[this.message_type];
    this.iconPath = require("@/assets/front_component/images/assets/" +
      this.messagetype.imgPath);
  },
  methods: {
    messageList(type, num) {
      this.loader = true;
      message
        .listNum(type, num)
        .then(
          (response) => {
            if (response != null) {
              this.messages = response.data.data;
              this.tempMessages = response.data.data;
              this.total = response.data.total;
              this.messages.forEach((message_single, i) => {
                let removeNbsp = message_single.content.replace(/&nbsp;/g, "");
                message_single.content = striptags(removeNbsp);

                this.getImage(message_single.id, i);

                User.show(message_single.from_id)
                  .then((response) => {
                    if (response != null) {
                      if (response.data.data.group.parent_id) {
                        Groups.show(response.data.data.group.parent_id)
                          .then((responseGroup) => {
                            if (responseGroup != null) {
                              //console.log(responseGroup.data.data.name);
                              response.data.data.group.name =
                                responseGroup.data.data.name;
                              message_single.from_user = response.data.data;
                            }
                          })
                          .catch((error) => {
                            message_single.from_user = response.data.data;
                            //console.log(error);
                          })
                          .finally(() => {});
                      } else {
                        message_single.from_user = response.data.data;
                      }
                    }
                  })
                  .catch((error) => {
                    //console.log(error);
                  })
                  .finally(() => {});
              });

              $(function () {
                $(".pop-modal").magnificPopup({
                  type: "inline",
                  preloader: false,
                  closeOnContentClick: false,
                  closeBtnInside: true,
                  removalDelay: 300,
                  gallery: {
                    //ギャラリー表示にする
                    enabled: true,
                  },
                });
              });
            }
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            //this.$router.push({ name: 'Front Error' });
          }
        )
        .catch((error) => {
          console.log(error);
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    messageListmore() {
      $("#load").show();

      $("#more_btn").hide();

      this.now_post_num = this.autoIncrease + 18;

      this.autoIncrease = this.now_post_num;

      message
        .listOffset(this.message_type, this.now_post_num)
        .then(
          (response) => {
            this.more_load = true;
            if (response != null) {
              let addMorePhotos = response.data.data;

              addMorePhotos.forEach((message_single, i) => {
                this.messages.push(message_single);
              });

              this.total = response.data.total;

              this.messages.forEach((message_single, i) => {
                let removeNbsp = message_single.content.replace(/&nbsp;/g, "");
                message_single.content = striptags(removeNbsp);

                this.getImage(message_single.id, i);

                User.show(message_single.from_id).then((response) => {
                  if (response != null) {
                    if (response.data.data.group.parent_id) {
                      Groups.show(response.data.data.group.parent_id)
                        .then((responseGroup) => {
                          if (responseGroup != null) {
                            response.data.data.group.name =
                              responseGroup.data.data.name;
                            message_single.from_user = response.data.data;
                          }
                        })
                        .catch((error) => {
                          message_single.from_user = response.data.data;
                        })
                        .finally(() => {});
                    } else {
                      message_single.from_user = response.data.data;
                    }
                  }
                });
              });

              $(function () {
                $(".pop-modal").magnificPopup({
                  type: "inline",
                  preloader: false,
                  closeOnContentClick: false,
                  closeBtnInside: true,
                  removalDelay: 300,
                  gallery: {
                    //ギャラリー表示にする
                    enabled: true,
                  },
                });
              });
            }
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    getImage(id, i) {
      message
        .showfile(id, "key", "photolog")
        .then(
          (response) => {
            if (response != "") {
              this.images = response.data.data;

              let path = this.images[0].storage_file_name;
              let fileApiPass = "/api/v1/file/" + path;
              Api()
                .get(fileApiPass, { responseType: "arraybuffer" })
                .then(
                  (response) => {
                    let blob = new Blob([response.data], {
                      type: response.headers["content-type"],
                    });
                    let url = window.URL || window.webkitURL;
                    let src = url.createObjectURL(blob);
                    this.messages[i].imgpath = src;
                  },
                  (error) => {
                    console.log(error);
                  }
                );
            }
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            //this.$router.push({ name: 'Front Error' });
          }
        )
        .catch(() => {
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {});
    },
  },
  mounted() {
    document.body.className = "page_board-archive";
    this.$nextTick(function () {
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
    });
  },
  updated() {
    if (this.more_load == true) {
      $("#load").hide();
      $("#more_btn").show();
      this.more_load = false;
    }
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
</style>
